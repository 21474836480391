.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  > img,
  > svg,
  > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.swiper-slide-zoomed {
  cursor: move;
}
