$seconday-infrared-wcag: #eb1035;
$primary-charcoal: #2f2e41;

$seconday-slategrey: #a1a3a6;
$seconday-nimbusgrey: #d9d9d9;
$secondary-anti-flash-white: #f3f3f3;
$seconday-infrared: #f83858;

$seconday-forrestgreen: #38c030;
$seconday-black: #666666;
$seconday-gold: #ffd700;

$danger-background: #fff2f4;
$unnamed-color-2f2e41: #2f2e4180;

$auth-form-width: 360px;

//setting up steps for controlling alternate styling on tables
:root {
  --ion-background-color: #ffffff;
  --ion-color-step-100: #f5f5f5;
  --ion-color-step-300: #f5f5f5;
}

// widgets:
@mixin desktop-textfield {
  margin: 0 0 13px 0;
  border: solid 1px rgba($seconday-nimbusgrey, 0.3);
  background: 0% 0% no-repeat padding-box;
  background-color: rgba($seconday-nimbusgrey, 0.3);

  border-radius: 5px;

  &::part(native) {
    --full-highlight-height: 0;
    border: none;
    padding-left: unset;
    min-height: unset;
    overflow: unset;
    background: unset;
  }

  ion-input {
    margin-left: 15px;
  }

  ion-icon {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &.ion-touched.ion-invalid {
    border: solid 1px var(--callToActionColor);
  }
}

@mixin desktop-button {
  margin-bottom: 15px;
  &::part(native) {
    width: 159px;
    height: 40px;
    border-radius: 6px;
    opacity: 1;
    text-transform: capitalize;
  }

  &.button-disabled::part(native) {
    background: 0% 0% no-repeat padding-box;
    background-color: $seconday-nimbusgrey;
  }
}

//modals:
@mixin modal-header-desktop {
  ion-toolbar {
    height: 40px;

    ion-label {
      font-size: 12px;
      color: $seconday-black;
    }

    ion-icon {
      width: 25px;
      height: 25px;
      color: $seconday-slategrey;
      cursor: pointer;
    }
  }

  &.header-md::after {
    display: none;
  }
}

@mixin modal-footer-desktop {
  ion-toolbar {
    ion-button {
      @include desktop-button;
    }
  }

  &.footer-md::before {
    display: none;
  }
}

@mixin error-message {
  font-size: 12px;
  letter-spacing: 0px;
  color: var(--callToActionColor);
  opacity: 1;
  text-align: center;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  --ion-item-background: var(--ion-color-step-150);
}

/*
 * -------------------------------------------
 * custom branded colors variables
 * -------------------------------------------
 */

@mixin custom-branded-colors {
  --globalHeaderColor: #222222; //1-System-Black
  --callToActionColor: #ed3186; //Primary-HotPink -- This is the primary
  --topNavigationBarColor: #2f2e41; //Primary-Charcoal
  --activeNavFunctionColor: #fd8924; //PartnerColor-01
  --secondaryFunctionColor: #ffffff; //1-System-White
  --moduleHeaderColor: #1dade4; //Primary-BrightBlue
  --moduleHeaderTextColor: #ffffff; //1-System-White
  --bottomNavigationColor: #2f2e41; //Primary-Charcoal
  --socialMediaIconColor: #ffffff; //1-System-White
  --dividerLineColor: #b9b9b9; //1-Gray
  --mainTextColor: #222222; //1-System-Black
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;

  --mainTextColor: #dbdbdb !important;
}

:root {
  @include custom-branded-colors();
}

@mixin branded-textfield {
  border: solid 1px rgba($seconday-nimbusgrey, 0.3);
  background: 0% 0% no-repeat padding-box;
  background-color: rgba($seconday-nimbusgrey, 0.3);
  border-radius: 5px;

  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;

  &::part(native) {
    .native-input {
      padding: 8px;
    }
  }

  &.ion-touched.ion-invalid {
    border: solid 1px $seconday-infrared-wcag;
  }
}
