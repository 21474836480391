@import "../variables.scss";

@media only screen and (min-width: 768px) {
  .common-modal {
    align-items: start;
    justify-content: right;
    &::part(content) {
      --width: 420px;
      --height: 100%;
      box-shadow: 0px 4px 10px #00000024;
    }
  }
  .order-physical-card-modal {
    align-items: start;
    justify-content: right;
    &::part(content) {
      --width: 520px;
      --height: 100%;
      box-shadow: 0px 4px 10px #00000024;
    }
  }
}

.errorMessageToast {
  --background: #f5d3d2;
  --color: black;
  font-size: small;
  font-weight: 600;
  --border-style: solid;
  --border-radius: 5px;
  --border-width: 2px;
  --border-color: #bb1313;
  --width: 80vw;
}

.errorMessageToast::part(button) {
  color: black;
  &:hover {
    background-color: #f5c4c3;
  }
}

.success-message {
  background: var(--globalHeaderColor);
  color: var(--secondaryFunctionColor);

  padding: 10px;
  border-radius: 5px;
  margin: 16px 0px 0px 0px;
  font-size: 14px;
  width: 100%;
}

.alert-radio-label.sc-ion-alert-md {
  text-overflow: clip;
  white-space: break-spaces;
  font-size: 14px;
}
