.content {
  flex: 1;
}

.footer {
  min-height: 76px;
}

.body-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow-x: auto;
}

@media only screen and (min-width: 768px) {
  .forgot-modal {
    &::part(content) {
      --width: 525px;
      --height: 380px;
      box-shadow: 0px 4px 10px #00000024;
      border-radius: 10px;
    }
  }

  .message-modal {
    &::part(content) {
      --width: 524px;
      --height: 280px;
      box-shadow: 0px 4px 10px #00000024;
      border-radius: 10px;
    }
  }
}
