.content {
  flex: 1;
}

.footer {
  min-height: 76px;
}

.body-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  overflow-x: auto;
}