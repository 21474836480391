/* heebo-hebrew-100-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url('./files/heebo-hebrew-100-normal.woff2') format('woff2'), url('./files/heebo-all-100-normal.woff') format('woff');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* heebo-latin-100-normal*/
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url('./files/heebo-latin-100-normal.woff2') format('woff2'), url('./files/heebo-all-100-normal.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
