@import "../../variables.scss";

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-mdc-card-header .mat-mdc-card-title {
  font-size: 20px;
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  font-size: 14px;
}


.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-mdc-chip {
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px;
  letter-spacing: normal;
}

.mat-mdc-form-field {
  font-size: inherit;
  font-weight: 200;
  line-height: 1.125;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 75%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-mdc-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-mdc-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}


.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-form-field-autofill-control:-webkit-autofill
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server[label]:not(:label-shown)
    + .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}


.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server[label]:not(:label-shown)
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

input.mat-mdc-input-element {
  margin-top: -0.0625em;
}

.mat-mdc-menu-item {
  font-size: 14px;
  font-weight: 400;
}

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-select-trigger {
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}


.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}


.mat-tab-label,
.mat-mdc-tab-link {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  letter-spacing: normal;
  margin: 0;
}

.mat-mdc-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 16px;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-mdc-list-base .mat-list-option {
  font-size: 16px;
}

.mat-mdc-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-mdc-list-base .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 14px;
}

.mat-mdc-list-base .mat-mdc-subheader {
  font-size: 14px;
  font-weight: 500;
}

.mat-mdc-list-base[dense] .mat-mdc-list-item {
  font-size: 12px;
}

.mat-mdc-list-base[dense] .mat-mdc-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-mdc-list-base[dense] .mat-mdc-list-item .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

.mat-mdc-list-base[dense] .mat-list-option {
  font-size: 12px;
}

.mat-mdc-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-mdc-list-base[dense] .mat-list-option .mat-line:nth-child(n + 2) {
  font-size: 12px;
}

.mat-mdc-list-base[dense] .mat-mdc-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-option {
  font-size: 16px;
}

.mat-mdc-optgroup-label {
  letter-spacing: normal;
}

.mat-mdc-simple-snack-bar {
  font-size: 14px;
}

.mat-mdc-snack-bar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}

@keyframes cdk-text-field-autofill-end {
  /*!*/
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}

.mat-accent .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: transparent;
}

.mat-warn .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: transparent;
}

.mat-mdc-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-mdc-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color:var(--topNavigationBarColor);
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: transparent;
  border-color: var(--topNavigationBarColor) !important;
  border: 2px solid;
  border-radius: 2px;
  box-sizing: border-box;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: var(--topNavigationBarColor);
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14),
    0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14),
    0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14),
    0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14),
    0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14),
    0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14),
    0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14),
    0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
    0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14),
    0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14),
    0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14),
    0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}


.mat-autocomplete-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #fff;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: #fff;
  background: #3f51b5;
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: var(--callToActionColor);
  color: #fff;
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  color: inherit;
  background: transparent;
}

.mat-mdc-button.mat-primary,
.mat-mdc-icon-button.mat-primary,
.mat-mdc-outlined-button.mat-primary {
  color: #3f51b5;
}

.mat-mdc-button.mat-accent,
.mat-mdc-icon-button.mat-accent,
.mat-mdc-outlined-button.mat-accent {
  color: var(--callToActionColor);
}

.mat-mdc-button.mat-warn,
.mat-mdc-icon-button.mat-warn,
.mat-mdc-outlined-button.mat-warn {
  color: #f44336;
}

.mat-mdc-button.mat-primary.mat-button-disabled,
.mat-button.mat-accent.mat-button-disabled,
.mat-button.mat-warn.mat-button-disabled,
.mat-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-icon-button.mat-primary.mat-button-disabled,
.mat-icon-button.mat-accent.mat-button-disabled,
.mat-icon-button.mat-warn.mat-button-disabled,
.mat-icon-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-outlined-button.mat-primary.mat-button-disabled,
.mat-stroked-button.mat-accent.mat-button-disabled,
.mat-stroked-button.mat-warn.mat-button-disabled,
.mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}


.mat-mdc-button.mat-primary .mat-button-focus-overlay,
.mat-mdc-icon-button.mat-primary .mat-button-focus-overlay,
.mat-mdc-outlined-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}

.mat-mdc-button.mat-accent .mat-button-focus-overlay,
.mat-mdc-icon-button.mat-accent .mat-button-focus-overlay,
.mat-mdc-outlined-button.mat-accent .mat-button-focus-overlay {
  background-color: var(--callToActionColor);
}

.mat-mdc-button.mat-warn .mat-button-focus-overlay,
.mat-mdc-icon-button.mat-warn .mat-button-focus-overlay,
.mat-mdc-outlined-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}

.mat-mdc-button.mat-button-disabled .mat-button-focus-overlay,
.mat-mdc-icon-button.mat-button-disabled .mat-button-focus-overlay,
.mat-mdc-outlined-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-mdc-button .mat-ripple-element,
.mat-mdc-icon-button .mat-ripple-element,
.mat-mdc-outlined-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #000;
}

.mat-mdc-outlined-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  color: #fff;
}

.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  color: #fff;
}

.mat-mdc-unelevated-button.mat-warn,
.mat-mdc-raised-button.mat-warn,
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  color: #fff;
}

.mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mdc-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  background-color: #3f51b5;
}

.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  background-color: var(--callToActionColor);
}

.mat-mdc-unelevated-button.mat-warn,
.mat-mdc-raised-button.mat-warn,
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  background-color: #f44336;
}

.mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mdc-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-unelevated-button.mat-primary .mat-ripple-element,
.mat-mdc-raised-button.mat-primary .mat-ripple-element,
.mat-mdc-fab.mat-primary .mat-ripple-element,
.mat-mdc-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-unelevated-button.mat-accent .mat-ripple-element,
.mat-mdc-raised-button.mat-accent .mat-ripple-element,
.mat-mdc-fab.mat-accent .mat-ripple-element,
.mat-mdc-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-unelevated-button.mat-warn .mat-ripple-element,
.mat-mdc-raised-button.mat-warn .mat-ripple-element,
.mat-mdc-fab.mat-warn .mat-ripple-element,
.mat-mdc-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button:not([class*="mat-elevation-z"]),
.mat-mdc-unelevated-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}


.mat-mdc-raised-button:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-mdc-raised-button.mat-button-disabled:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-fab:not([class*="mat-elevation-z"]),
.mat-mdc-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-fab:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]),
.mat-mdc-mini-fab:not(.mat-button-disabled):active:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-mdc-fab.mat-button-disabled:not([class*="mat-elevation-z"]),
.mat-mdc-mini-fab.mat-button-disabled:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #000;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir="rtl"] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eee;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #fff;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-mdc-card {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-card.mat-card-flat:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: var(--topNavigationBarColor);
}

.mat-checkbox-checkmark-path {
  stroke: var(--topNavigationBarColor) !important;
}

.mat-checkbox-mixedmark {
  background-color: var(--topNavigationBarColor);
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: transparent;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-checkbox .mat-ripple-element {
  background-color: #000;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: transparent;
}

.mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-chip.mat-mdc-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}

.mat-mdc-chip.mat-mdc-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-chip.mat-mdc-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}

.mat-mdc-chip.mat-mdc-standard-chip::after {
  background: #000;
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: #fff;
}


.mdc-chip.mdc-chip--selected.mdc-chip--primary .mdc-chip__icon--trailing {
  color: #fff;
  opacity: 0.4;
}

.mdc-chip.mdc-chip--selected.mdc-chip--primary .mdc-ripple-surface {
  background-color: rgba(255, 255, 255, 0.1);
}

.mdc-chip.mdc-chip--selected.mdc-chip--warn {
  background-color: #f44336;
  color: #fff;
}

.mdc-chip.mdc-chip--selected.mdc-chip--warn .mdc-chip__icon--trailing {
  color: #fff;
  opacity: 0.4;
}

.mdc-chip.mdc-chip--selected.mdc-chip--warn .mdc-ripple-surface {
  background-color: rgba(255, 255, 255, 0.1);
}

.mdc-chip.mdc-chip--selected.mdc-chip--accent {
  background-color: var(--callToActionColor);
  color: #fff;
}

.mdc-chip.mdc-chip--selected.mdc-chip--accent .mdc-chip__icon--trailing {
  color: #fff;
  opacity: 0.4;
}

.mdc-chip.mdc-chip--selected.mdc-chip--accent .mdc-ripple-surface {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: var(--callToActionColor);
  color: #fff;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(63, 81, 181, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: var(--callToActionColor);
  color: #fff;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content.mat-accent
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: #fff;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content.mat-warn
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn
  .cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: var(--callToActionColor);
}

.mat-datepicker-toggle-active.mat-accent {
  color: var(--callToActionColor);
}

.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
    background: #fff;
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled="true"] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-text-field--focused .mdc-floating-label {
  color: #3f51b5;
}

.mdc-text-field--focused .mdc-floating-label.mdc-text-field--accent {
  color: var(--callToActionColor);
}

.mdc-text-field--focused .mdc-floating-label.mdc-text-field--warn {
  color: #f44336;
}

.mdc-text-field--focused .mdc-floating-label-required {
  color: var(--callToActionColor);
}

.mdc-line-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--focused .mdc-line-ripple {
  background-color: #3f51b5;
}

.mdc-text-field--focused .mdc-line-ripple.mdc-text-field--accent {
  background-color: var(--callToActionColor);
}

.mdc-text-field--focused .mdc-line-ripple.mdc-text-field--warn {
  background-color: #f44336;
}

.mdc-select--focused:not(.mdc-select--invalid) .mdc-select__anchor::after {
  color: #3f51b5;
}

.mdc-select--focused:not(.mdc-select--invalid).mdc-select--accent .mdc-select__anchor::after {
  color: var(--callToActionColor);
}

.mdc-select--focused:not(.mdc-select--invalid).mdc-select--warn .mdc-select__anchor::after {
  color: #f44336;
}

.mdc-text-field--invalid .mdc-floating-label {
  color: #f44336;
}

.mdc-text-field--invalid .mdc-floating-label.mdc-text-field--accent,
.mdc-text-field--invalid .mdc-floating-label-required {
  color: #f44336;
}

.mdc-text-field--invalid .mdc-line-ripple,
.mdc-text-field--invalid .mdc-line-ripple.mdc-text-field--accent {
  background-color: #f44336;
}

.mdc-text-field-helper-text--persistent {
  color: #f44336;
}

.mdc-text-field--legacy .mdc-floating-label {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field--legacy .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-text-field--legacy .mdc-line-ripple {
  background-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--legacy.mdc-text-field--disabled .mdc-line-ripple {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mdc-text-field--standard .mdc-line-ripple {
  background-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--standard.mdc-text-field--disabled .mdc-line-ripple {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mdc-text-field--filled .mdc-text-field__ripple {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__ripple {
  background-color: rgba(0, 0, 0, 0.02);
}

.mdc-text-field--filled .mdc-line-ripple::before {
  background-color: rgba(0, 0, 0, 0.42);
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  background-color: transparent;
}

.mdc-text-field--outlined .mdc-notched-outline {
  color: rgba(0, 0, 0, 0.12);
}

.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  color: #3f51b5;
}

.mdc-text-field--outlined.mdc-text-field--focused.mdc-text-field--accent .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused.mdc-text-field--accent .mdc-notched-outline__trailing {
  color: var(--callToActionColor);
}

.mdc-text-field--outlined.mdc-text-field--focused.mdc-text-field--warn .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused.mdc-text-field--warn .mdc-notched-outline__trailing {
  color: #f44336;
}

.mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid .mdc-notched-outline__trailing {
  color: #f44336;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #3f51b5;
}

.mat-icon.mat-accent {
  color: var(--callToActionColor);
}

.mat-icon.mat-warn {
  color: #f44336;
}

.mdc-select__anchor::after {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-select__anchor:disabled::after,
.mdc-select--disabled .mdc-select__anchor::after {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-text-field__input {
  caret-color: #3f51b5;
}

.mdc-text-field__input::placeholder {
  color: $seconday-slategrey;
}

.mdc-text-field__input::-moz-placeholder {
  color: $seconday-slategrey;
}

.mdc-text-field__input::-webkit-input-placeholder {
  color: $seconday-slategrey;
}

.mdc-text-field__input:-ms-input-placeholder {
  color: $seconday-slategrey;
}

.mdc-text-field--accent .mdc-text-field__input {
  caret-color: var(--callToActionColor);
}

.mdc-text-field--warn .mdc-text-field__input,
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #f44336;
}

.mdc-select--invalid .mdc-select__anchor::after {
  color: #f44336;
}

.mdc-list .mdc-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-list .mdc-list-item--disabled {
  background-color: #eee;
}

.mdc-list-item:hover,
.mdc-list-item:focus,
.mdc-list-item--activated:hover,
.mdc-list-item--activated:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mdc-list-item--selected,
.mdc-list-item--selected:hover,
.mdc-list-item--selected:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mdc-menu-surface {
  background: #fff !important;
}

.mdc-menu-surface:not([class*="mdc-elevation"]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mdc-menu-item[disabled],
.mdc-menu-item[disabled]::after,
.mdc-menu-item[disabled] .mdc-icon {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-menu-item .mdc-icon,
.mdc-menu-item__submenu::after {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-menu-item:hover:not([disabled]),
.mdc-menu-item:focus:not([disabled]),
.mdc-menu-item--highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__pagination {
  background: #fff;
}

.mdc-data-table__pagination-button {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mdc-data-table__pagination-button--first,
.mdc-data-table__pagination-button--last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mdc-linear-progress__buffer {
  background-color: #cbd0e9;
}

.mdc-linear-progress__bar-inner {
  background-color: #3f51b5;
}

.mdc-linear-progress--accent .mdc-linear-progress__buffer {
  background-color: #fbccdc;
}

.mdc-linear-progress--accent .mdc-linear-progress__bar-inner {
  background-color: var(--callToActionColor);
}

.mdc-linear-progress--warn .mdc-linear-progress__buffer {
  background-color: #f9ccc9;
}

.mdc-linear-progress--warn .mdc-linear-progress__bar-inner {
  background-color: #f44336;
}

.mdc-circular-progress__circle {
  stroke: #3f51b5;
}

.mdc-circular-progress--accent .mdc-circular-progress__circle {
  stroke: var(--callToActionColor);
}

.mdc-circular-progress--warn .mdc-circular-progress__circle {
  stroke: #f44336;
}

.mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mdc-radio--primary.mdc-radio--checked .mdc-radio__outer-circle {
  border-color: #3f51b5;
}

.mdc-radio--primary .mdc-radio__inner-circle,
.mdc-radio--primary .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--primary.mdc-radio--checked .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--primary:active .mdc-radio__ripple .mdc-ripple-upgraded--background {
  background-color: #3f51b5;
}

.mdc-radio--accent.mdc-radio--checked .mdc-radio__outer-circle {
  border-color: var(--callToActionColor);
}

.mdc-radio--accent .mdc-radio__inner-circle,
.mdc-radio--accent .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--accent.mdc-radio--checked .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--accent:active .mdc-radio__ripple .mdc-ripple-upgraded--background {
  background-color: var(--callToActionColor);
}

.mdc-radio--warn.mdc-radio--checked .mdc-radio__outer-circle {
  border-color: #f44336;
}

.mdc-radio--warn .mdc-radio__inner-circle,
.mdc-radio--warn .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--warn.mdc-radio--checked .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--warn:active .mdc-radio__ripple .mdc-ripple-upgraded--background {
  background-color: #f44336;
}

.mdc-radio--disabled.mdc-radio--checked .mdc-radio__outer-circle,
.mdc-radio--disabled .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio--disabled .mdc-radio__ripple .mdc-ripple-upgraded--background,
.mdc-radio--disabled .mdc-radio__inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}

.mdc-radio--disabled .mdc-radio__label {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-radio .mdc-ripple-upgraded--background {
  background-color: #000;
}

.mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-select__placeholder {
  color: $seconday-slategrey;
}

.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-select__dropdown-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-select__menu {
  background: #fff;
}

.mdc-select__menu:not([class*="mdc-elevation"]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-select__menu .mdc-list-item--selected:not(.mdc-list-item--multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mdc-select--focused.mdc-select--primary .mdc-select__dropdown-icon {
  color: #3f51b5;
}

.mdc-select--focused.mdc-select--accent .mdc-select__dropdown-icon {
  color: var(--callToActionColor);
}

.mdc-select--focused.mdc-select--warn .mdc-select__dropdown-icon {
  color: #f44336;
}

.mdc-select--invalid .mdc-select__dropdown-icon {
  color: #f44336;
}

.mdc-select--disabled .mdc-select__dropdown-icon {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: var(--callToActionColor);
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer.mat-drawer-push {
  background-color: #fff;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir="rtl"] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir="rtl"] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mdc-switch--checked .mdc-switch__thumb {
  background-color: var(--callToActionColor);
}

.mdc-switch--checked .mdc-switch__track {
  background-color: rgba(255, 64, 129, 0.54);
}

.mdc-switch--checked .mdc-ripple-upgraded {
  background-color: var(--callToActionColor);
}

.mdc-switch--checked.mdc-switch--primary .mdc-switch__thumb {
  background-color: #3f51b5;
}

.mdc-switch--checked.mdc-switch--primary .mdc-switch__track {
  background-color: rgba(63, 81, 181, 0.54);
}

.mdc-switch--checked.mdc-switch--primary .mdc-ripple-upgraded {
  background-color: #3f51b5;
}

.mdc-switch--checked.mdc-switch--warn .mdc-switch__thumb {
  background-color: #f44336;
}

.mdc-switch--checked.mdc-switch--warn .mdc-switch__track {
  background-color: rgba(244, 67, 54, 0.54);
}

.mdc-switch--checked.mdc-switch--warn .mdc-ripple-upgraded {
  background-color: #f44336;
}

.mdc-switch:not(.mdc-switch--checked) .mdc-ripple-upgraded {
  background-color: #000;
}

.mdc-switch__thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--topNavigationBarColor);
}

.mdc-switch__track {
  background-color: rgba(0, 0, 0, 0.38);
}

.mdc-slider__track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mdc-slider--primary .mdc-slider__track-fill,
.mdc-slider--primary .mdc-slider__thumb,
.mdc-slider--primary .mdc-slider__thumb-label {
  background-color: #3f51b5;
}

.mdc-slider--primary .mdc-slider__thumb-label-text {
  color: #fff;
}

.mdc-slider--primary .mdc-slider__focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}

.mdc-slider--accent .mdc-slider__track-fill,
.mdc-slider--accent .mdc-slider__thumb,
.mdc-slider--accent .mdc-slider__thumb-label {
  background-color: var(--callToActionColor);
}

.mdc-slider--accent .mdc-slider__thumb-label-text {
  color: #fff;
}

.mdc-slider--accent .mdc-slider__focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}

.mdc-slider--warn .mdc-slider__track-fill,
.mdc-slider--warn .mdc-slider__thumb,
.mdc-slider--warn .mdc-slider__thumb-label {
  background-color: #f44336;
}

.mdc-slider--warn .mdc-slider__thumb-label-text {
  color: #fff;
}

.mdc-slider--warn .mdc-slider__focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mdc-slider:hover .mdc-slider__track-background,
.mdc-slider:focus .mdc-slider__track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mdc-slider--disabled .mdc-slider__track-background,
.mdc-slider--disabled .mdc-slider__track-fill,
.mdc-slider--disabled .mdc-slider__thumb {
  background-color: rgba(0, 0, 0, 0.26);
}

.mdc-slider--disabled:hover .mdc-slider__track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mdc-slider--min-value .mdc-slider__focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-slider--min-value.mdc-slider__thumb-label-showing .mdc-slider__thumb,
.mdc-slider--min-value.mdc-slider__thumb-label-showing .mdc-slider__thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}

.mdc-slider--min-value.mdc-slider__thumb-label-showing:focus .mdc-slider__thumb,
.mdc-slider--min-value.mdc-slider__thumb-label-showing:focus .mdc-slider__thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}

.mdc-slider--min-value:not(.mdc-slider__thumb-label-showing) .mdc-slider__thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.mdc-slider--min-value:not(.mdc-slider__thumb-label-showing):hover .mdc-slider__thumb,
.mdc-slider--min-value:not(.mdc-slider__thumb-label-showing):focus .mdc-slider__thumb {
  border-color: rgba(0, 0, 0, 0.38);
}

.mdc-slider--min-value:not(.mdc-slider__thumb-label-showing):hover.mdc-slider--disabled .mdc-slider__thumb,
.mdc-slider--min-value:not(.mdc-slider__thumb-label-showing):focus.mdc-slider--disabled .mdc-slider__thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mdc-slider--has-ticks .mdc-slider__wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mdc-slider--horizontal .mdc-slider__ticks {
  background-image: repeating-linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
  background-image: -moz-repeating-linear-gradient(
    0.0001deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
}

.mdc-slider--vertical .mdc-slider__ticks {
  background-image: repeating-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7) 2px,
    transparent 0,
    transparent
  );
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: #fff;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: #fff;
}

.mat-step-header.mat-accent .mat-step-icon {
  color: #fff;
}

.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color:var(--callToActionColor);
  color: #fff;
}

.mat-step-header.mat-warn .mat-step-icon {
  color: #fff;
}

.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: #fff;
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: #fff;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


.mdc-tab-bar--inverted .mdc-tab-bar,
.mdc-tab-bar--inverted .mdc-tab-scroller__scroll-content {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mdc-tab__text-label,
.mdc-tab__icon {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-tab--disabled .mdc-tab__text-label,
.mdc-tab--disabled .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.38);
}

.mdc-tab-scroller__indicator {
  border-color: rgba(0, 0, 0, 0.87);
}

.mdc-tab-scroller--disabled .mdc-tab-scroller__indicator {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-tab-group[class*="mat-background-"] .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar[class*="mat-background-"] {
  border-bottom: none;
  border-top: none;
}

.mat-mdc-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}

.mat-mdc-tab-group.mat-primary.mat-background-primary > .mat-mdc-tab-header .mat-ink-bar,
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar,
.mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff;
}

.mat-mdc-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}

.mat-mdc-tab-group.mat-accent .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: var(--callToActionColor);
}

.mat-mdc-tab-group.mat-accent.mat-background-accent > .mat-mdc-tab-header .mat-ink-bar,
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar,
.mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff;
}

.mat-mdc-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-mdc-tab-group.mat-warn .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}

.mat-mdc-tab-group.mat-warn.mat-background-warn > .mat-mdc-tab-header .mat-ink-bar,
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar,
.mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: #fff;
}

.mat-mdc-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header,
.mat-tab-group.mat-background-primary > .mat-tab-link-container,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-tab-header,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #3f51b5;
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-tab-label,
.mat-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-tab-link,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: #fff;
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-tab-link.mat-tab-disabled,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: #fff;
}

.mat-mdc-tab-group.mat-background-primary
  > .mat-mdc-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-mdc-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header,
.mat-tab-group.mat-background-accent > .mat-tab-link-container,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-tab-header,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: var(--callToActionColor);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-tab-label,
.mat-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-tab-link,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #fff;
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-tab-link.mat-tab-disabled,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #fff;
}

.mat-mdc-tab-group.mat-background-accent
  > .mat-mdc-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-mdc-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-mdc-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),
.mat-mdc-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header,
.mat-tab-group.mat-background-warn > .mat-tab-link-container,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-tab-header,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-tab-label,
.mat-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-tab-link,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: #fff;
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-tab-link.mat-tab-disabled,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: #fff;
}

.mat-mdc-tab-group.mat-background-warn
  > .mat-mdc-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn
  > .mat-tab-header
  .mat-tab-header-pagination-disabled
  .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: #fff;
}

.mat-toolbar.mat-accent {
  background: var(--callToActionColor);
  color: #fff;
}

.mat-toolbar.mat-warn {
  background: #f44336;
  color: #fff;
}


.mdc-toolbar .mdc-text-field__ripple,
.mdc-toolbar .mdc-text-field__underline,
.mdc-toolbar .mdc-text-field--focused .mdc-text-field__ripple {
  background-color: currentColor;
}

.mat-toolbar .mat-mdc-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-mdc-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: 56px;
  }
}

.mat-mdc-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: #fff;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-mdc-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-mdc-snack-bar-action {
  color: var(--callToActionColor);
}

//custom
.drop-down-dashboard {
  margin-top: 30px;
  min-width: calc(100% + 20px) !important;
  margin-top: 30px;
  margin-left: 30px;
}

.mat-sort-header-content {
  text-align: inherit !important;
}

.drop-down-data-table-menu-select {
  min-width: calc(100% + 20px) !important;
  margin-top: 30px;
  margin-left: 5px;
}

.drop-down-data-table-menu-multiple-select {
  min-width: calc(100% - 64px) !important;
  margin-top: 30px;
  margin-left: 30px;
}
